import "./styles/main.css";

import 'animate.css';
import Maps from "./Maps"
import First_block_img from "./img/first-block.jpg"

function App() {
  return (
    <div className="App">
        <div className="o-layout first-block">
            <div className="first-block__content animate__animated animate__fadeIn">
                <div className="first-block__div1"></div>
                <div className="first-block__div2">
                    <div className="first-block__header">
                        <p>Приглашение на свадьбу</p>
                    </div>
                    <div className="first-block__footer">
                        <div className="first-block__main-header">
                            <p className="main-header-1">Андрей</p>
                            <p className="main-header-2">&</p>
                            <p className="main-header-3">Мария</p>
                        </div>
                        <div className="first-block__border"></div>
                        <p>26.07.25</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="o-layout indicaton-block">
            <div className="indicaton-block__content">
                <h1>Дорогие родные и друзья!</h1>
                <div className="indicaton-block__block-main">
                    <p className="text">Очень рады пригласить Вас на самое трогательное и счастливое событие в нашей жизни —
                    нашу свадьбу!</p>
                    <div className="indicaton-block__block-sub">
                            <p className="text indicaton-block__num">26.07.25</p>                 
                    </div>
                    <p className="text">Мы будем безумно рады видеть вас в кругу наших гостей!</p>
                </div>
            </div>
        </div>
        <div className="o-layout place-block">
            <div className="place-block__content">
                <h1>Место проведения торжества</h1>
                <p className="text">Нашу свадьбу мы решили отметить в загородном комплексе «Завидное»</p>
                <div className="maps">
                <Maps />
                </div>
                <div className="place-block__block-sub">
                    <h2 className="text place-block__sub-text wavy-text">Адрес: рабочий посёлок Измайлово, 23, стр. 1</h2>
                </div>  
            </div>
        </div>
        <div className="o-layout program-block">
            <div className="program-block__block-header">
                        <div className="program-block__block-subheader"></div>
            </div>
            <div className="program-block__content">
                <div className="program-block__block-body">
                    <h1>Программа дня</h1>                
                    <div className="program-div">
                        <p className="text program-text"><h2>14:00</h2><span className="wavy-text">- сбор гостей</span></p>
                        <p className="text">Вас ждёт приветственный фуршет с легким аперитивом и закусками</p>
                    </div>
                    <div className="program-div">
                        <p className="text program-text"><h2>15:00</h2><span className="wavy-text">- свадебная церемония</span></p>
                        <p className="text">Вы станете свидетелями трогательного момента</p>
                    </div>
                    <div className="program-div">
                        <p className="text program-text"><h2>16:00</h2><span className="wavy-text">- банкет</span></p>
                        <p className="text">Время насладиться вкусной едой, танцами и развлечениями</p>
                    </div>
                    <div className="program-div">
                        <p className="text program-text"><h2>22:00</h2><span className="wavy-text">- окончание мероприятия</span></p>
                        <p className="text">Мы попрощаемся с гостями и поблагодарим вас, за то, что вы разделили с нами этот особенный день</p>
                    </div>  
                </div>
            </div>
        </div>
        <div className="o-layout dresscode-block">
            <div className="dresscode-block__content">
                <h1>Дресс-код</h1>
                <p className="text">Нам будет очень приятно, если вы учтете наши пожелания и при выборе одежды
                предпочтёте вечерние наряды в представленной ниже гамме:</p>
                <div className="dresscode-block__main">
                    <div className="dresscode-1"></div>
                    <div className="dresscode-2"></div>
                    <div className="dresscode-3"></div>
                    <div className="dresscode-4"></div>
                    <div className="dresscode-5"></div>
                    <div className="dresscode-6"></div>
                </div>
            </div>
        </div>
        <div className="o-layout details-block ">
            <div className="details-block__content">
                <h1>Детали</h1>
                <h2>Подарки</h2>
                <p className="text">Ваши пожелания в конвертах будут лучшими подарками, которые помогут исполнить наши
                мечты.</p>
                <h2>Цветы</h2>
                <p className="text">Просим не дарить нам цветы - мы не успеем насладиться их красотой. По желанию, вы можете вместо букета приносить с собой бутылку вашего любимого напитка с этикеткой, где будут написаны ваши пожелания, день или особое событие, когда мы должны его открыть.</p>
            </div>
        </div>
        <div className="o-layout confirm-block">
            <div className="confirm-block__content">
                <h2>Подтвердите, пожалуйста, своё присутствие на торжестве</h2>
                <div className="a-buttons">
                    <a className="a-button" href="https://t.me/chasm_ink" target="_blank" rel="noopener noreferrer">Маша<span></span><span></span><span></span><span></span></a>
                    <a className="a-button" href="https://t.me/sa_w_a" target="_blank" rel="noopener noreferrer">Андрей<span></span><span></span><span></span><span></span></a>
                </div>
                <p className="text">Мы будем счастливы видеть вас на нашем торжестве!</p>
            </div>
        </div>
    </div>
  );
}

export default App;