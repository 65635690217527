import React from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import customMarkerImage from './img/path-to-your-image-file.png'; // Adjust the path as needed

class Maps extends React.Component {
    render() {
        return (
            <YMaps>
                <Map
                    defaultState={{
                        center: [55.560845, 37.639860],
                        zoom: 16,
                        controls: ["zoomControl", "fullscreenControl"],
                    }}
                    modules={["control.ZoomControl", "control.FullscreenControl"]}
                    style={{ width: '100%', height: '100%' }}
                >
                    <Placemark
                        geometry={[55.560845, 37.639860]}
                        properties={{
                            iconCaption: 'Custom Placemark',
                        }}
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: customMarkerImage,
                            iconImageSize: [50, 50],
                            iconImageOffset: [-15, -30],
                        }}
                    />
                </Map>
            </YMaps>
        );
    }
}

export default Maps;


